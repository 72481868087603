import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../utils/axios';
import { createDynamicURL } from '../../utils/dynamicParams';
const initialState = {
    status: 'idle',
    error: null,
    classes: [],
    message: '',
    configs: [],
    configValues: [],
    meta: { currentPage: 0,
        nextPage: false,
        prevPage: false,
        totalPages: 0,
        totalRecords: 0 },
    class: null,
};
// ----------------------------------------------------------------------
export const getClasses = createAsyncThunk('classes/getClasses', async ({ orderBy = 'id', order = 'desc', page = 0, rowsPerPage = 5, filterClass = '', filterName = '', isPaging = true, } = {}) => {
    const queryParams = {
        search: {
            name: filterName,
            'rentableType.name': filterClass,
        },
        page: Number(page) + 1,
        perPage: rowsPerPage,
        orderBy,
        sortedBy: order,
        isPaging,
        searchJoin: 'OR',
    };
    const url = createDynamicURL('/classes', queryParams);
    try {
        const response = await axios.get(url);
        const data = await response.data;
        if (response.status === 200) {
            return data;
        }
        throw new Error(response.statusText);
    }
    catch (err) {
        return Promise.reject(err.message ? err.message : 'something went wrong');
    }
});
export const createClass = createAsyncThunk('classes/createClass', async (body) => {
    let data;
    try {
        const response = await axios.post(`/classes`, {
            description: body?.description,
            name: body.name,
            rentableTypeId: body.rentableTypeId,
            configValue: body.configValue,
        });
        data = await response.data;
        if (response.status === 200) {
            return data;
        }
        throw new Error(response.statusText);
    }
    catch (err) {
        return Promise.reject(err.message ? err.message : err);
    }
});
export const updateClass = createAsyncThunk('classes/updateClass', async (category) => {
    let data;
    const { id, name, description, rentableTypeId, configValue, ...rest } = category;
    try {
        const response = await axios.patch(`/classes/${id}`, {
            name,
            description,
            rentableTypeId,
            configValue,
        });
        data = await response.data;
        if (response.status === 200) {
            return {
                data,
                message: 'Classes has been updated successfully !',
            };
        }
        throw new Error(response.statusText);
    }
    catch (err) {
        return Promise.reject(err.message ? err.message : err);
    }
});
export const getOneClass = createAsyncThunk('classes/getOneClass', async (id) => {
    let data;
    try {
        const response = await axios.get(`/classes/${id}`);
        data = await response.data;
        if (response.status === 200) {
            return {
                data,
            };
        }
        throw new Error(response.statusText);
    }
    catch (err) {
        return Promise.reject(err.message ? err.message : err);
    }
});
export const deleteClass = createAsyncThunk('classes/deleteClass', async ({ id, getParams }, thunkAPI) => {
    try {
        const response = await axios.delete(`/classes/${id}`);
        if (response.status === 200) {
            if (getParams)
                thunkAPI.dispatch(getClasses({ ...getParams }));
            return {
                message: 'classes has been deleted successfully !',
            };
        }
        throw new Error(response.statusText);
    }
    catch (err) {
        return Promise.reject(err.message ? err.message : err);
    }
});
export const deleteManyClasses = createAsyncThunk('classes/deleteManyclasses', async ({ ids, getParams }, thunkAPI) => {
    try {
        const response = await axios.delete(`/classes/many`, { data: { ids } });
        if (response.status === 200) {
            thunkAPI.dispatch(getClasses({ ...getParams }));
            return {
                message: 'classes has been deleted successfully !',
            };
        }
        throw new Error(response.statusText);
    }
    catch (err) {
        return Promise.reject(err.message ? err.message : err);
    }
});
const slice = createSlice({
    name: 'class',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getClasses.pending, (state) => {
            state.status = 'loading';
        })
            .addCase(getClasses.fulfilled, (state, action) => {
            state.status = 'succeeded';
            state.classes = action.payload.data;
            state.meta = action.payload.meta;
        })
            .addCase(getClasses.rejected, (state, action) => {
            state.status = 'failed';
        })
            .addCase(createClass.pending, (state) => {
            state.status = 'loading';
        })
            .addCase(createClass.fulfilled, (state, action) => {
            state.status = 'succeeded';
        })
            .addCase(createClass.rejected, (state, action) => {
            state.status = 'failed';
        })
            .addCase(updateClass.pending, (state) => {
            state.status = 'loading';
        })
            .addCase(updateClass.fulfilled, (state, action) => {
            state.status = 'succeeded';
        })
            .addCase(updateClass.rejected, (state, action) => {
            state.status = 'failed';
        })
            .addCase(getOneClass.pending, (state) => {
            state.status = 'loading';
        })
            .addCase(getOneClass.fulfilled, (state, action) => {
            state.status = 'succeeded';
            state.class = action.payload.data.data;
        })
            .addCase(getOneClass.rejected, (state, action) => {
            state.status = 'failed';
        });
    },
});
// Reducer
export const reducer = slice.reducer;
export default slice;
